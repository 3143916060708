import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import './styles/quasar.scss'
import lang from 'quasar/lang/ro.js'
import interceptors from './modules/interceptors';
import '@quasar/extras/material-icons/material-icons.css'
import './assets/site.scss';
import dotenv from 'dotenv';
import { createI18n } from 'vue-i18n';
import { defaultLocale} from './language';
import en from './language/en.json';
import ro from './language/ro.json';
import hu from './language/hu.json';
import VueGtag from "vue-gtag";

const messages = {
    en: {
      message: en
    },
    ro: {
      message:
        ro
    },
    hu: {
      message:
        hu
    }
  }
  
  // 2. Create i18n instance with options
  const i18n = createI18n({
    locale: defaultLocale, // set locale
    fallbackLocale: 'hu', // set fallback locale
    globalInjection: true,
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
  })
  
dotenv.config();

interceptors();
const quasarUserOptions={
    config: {},
    plugins: {
        Loading,AppFullscreen, Notify, Dialog
    },
    lang: lang

}


createApp(App).use(Quasar, quasarUserOptions).use(i18n).use(store).use(router).use(VueGtag, {

  appName: 'B2B Melinda',
  pageTrackerScreenviewEnabled: true,
  config: { 
    id: "G-WB9W362WRD",
  },
  globalObjectName: 'analytics'
}, router).mount('#app')
